import React, { useState, useEffect } from "react";
import { uploadImage, updateOfferSliderImage } from "./dbhelper";
const Offers = () => {
  const [offerSlider, setOfferSlider] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentOffer, setCurrentOffer] = useState(null);
  const [formData, setFormData] = useState({});

  const [showWarning, setShowWarning] = useState(false);
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };
  useEffect(() => {
    handleResize(); 
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const apiKey = process.env.REACT_APP_API_KEY;
    const apiurl = process.env.REACT_APP_API_URL;
    fetch(`${apiurl}/info`, {
      method: "GET",
      headers: {
        "api-key": apiKey,
      },
    })
      .then((response) => response.json())
      .then((data) => setOfferSlider(data.offerslider))
      .catch((error) =>
        console.error("Error fetching offer slider data:", error)
      );
  }, []);
  function extractNumberFromString(str) {
    if (typeof str !== "string") {
      return NaN;
    }
    const match = str.match(/\d+$/);
    if (match) {
      return parseInt(match[0], 10);
    }
    return NaN;
  }
  const handleUpdateClick = (offer) => {
    setCurrentOffer(offer);
    let newid = extractNumberFromString(offer.name);
    
    setFormData({
      id: newid,
      image: offer.image,
    });
    
    setShowModal(true);
  };
  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Create a new URL from the file object
      const imageUrl = URL.createObjectURL(file);
      setFormData({ ...formData, image: imageUrl });
    }
  };  

  const handleSubmit = async () => {
    try {
      let imageUrl;
      if (typeof formData.image === 'string') {
        // If formData.image is a URL, convert it back to a File object
        const response = await fetch(formData.image);
        const blob = await response.blob();
        const file = new File([blob], 'image.png', { type: blob.type });
        imageUrl = await uploadImage(file);
      } else {
        // If formData.image is a File object, upload it directly
        imageUrl = await uploadImage(formData.image);
      }
  
      await updateOfferSliderImage(formData.id, imageUrl);
      const updatedOfferSlider = { ...offerSlider };
      updatedOfferSlider[currentOffer.name] = imageUrl;
      setOfferSlider(updatedOfferSlider);
  
      alert("Image updated successfully!");
      setShowModal(false);
    } catch (error) {
      console.error("Error updating offer image:", error);
      alert("Failed to update the image. Please try again.");
    }
  };


  return (
    <div>
    {showWarning ? (
      <p>Please expand your browser window to ensure a seamless user experience.</p>
    ) : (
    <div className="offers-container">
      <div className="offers-slider">
        {Object.entries(offerSlider).map(([key, value]) => (
          <div key={key} className="offer-card bigger-card">
            <div className="offer-details">
              <img src={value} alt={`Offer ${key}`} className="offer-image" />
              <div className="offers-actions action-row">
                <button
                  className="update-btn"
                  onClick={() => handleUpdateClick({ name: key, image: value })}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* Modal for updating offers */}
      {showModal && (
        <>
          <div
            className="offers-overlay offers-overlay-active"
            onClick={() => setShowModal(false)}
          ></div>
          <div className="offers-modal offers-modal-active">
            <div className="offers-modal-header">Update Offer</div>
            <div className="offers-modal-body">
              {formData.image && (
                <img
                  src={formData.image}
                  alt="Preview"
                  className="offers-image-preview"
                />
              )}
            </div>
            <div className="offers-modal-footer">
              <div className="left-buttons">
                <label
                  for="file-upload"
                  className="custom-file-upload blue-button"
                >
                  New Image
                </label>
                <input
                  id="file-upload"
                  type="file"
                  name="image"
                  onChange={handleFileChange}
                />
              </div>
              <div className="right-buttons">
                <button className="submit-btn" onClick={handleSubmit}>
                  Submit
                </button>
                <button
                  className="cancel-btn"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
     )}
    </div>
  );
};
export default Offers;
