import { createClient } from '@supabase/supabase-js'
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY
const supabase = createClient(supabaseUrl, supabaseKey)

export const updateProfile = async (formData) => {
  const { heading, subheading, twitter, instagram, facebook, youtube, main_logo, pdf } = formData;
  const updates = {
    heading,
    subheading,
    twitter,
    instagram,
    facebook,
    youtube,
    main_logo,
    pdf,
  };
  let { error } = await supabase.from('settings').update(updates).eq('id', 1);
  if (error) {
    console.error('Error updating settings:', error);
    throw error;
  }
  console.log('Settings updated successfully');
};

export const updateOfferSliderImage = async (id, imageUrl) => {
  try {
    const { error } = await supabase
      .from('offerslider')
      .update({ image_url: imageUrl })
      .eq('id', id);

    if (error) {
      console.error('Error updating offerslider image:', error);
      throw error;
    }

    console.log('Offerslider image updated successfully');
  } catch (error) {
    console.error('Error updating offerslider image:', error);
    throw error;
  }
};

export const uploadImage = async (file) => {
  try {
    const fileName = `${new Date().toISOString()}.${file.name.split('.').pop()}`;
    const { data, error } = await supabase.storage
      .from('cafe-images')
      .upload(`public/${fileName}`, file);
    if (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
    console.log('Image uploaded successfully:', data);
    const publicURL = `${supabase.storageUrl}/object/public/cafe-images/public/${fileName}`;
    return publicURL;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

const generateRandomNumber = () => {
  return Math.floor(Math.random() * 90000) + 10000; // Generate a random 5-digit number
};

export const uploadPDF = async (file) => {
  try {
    const randomNumber = generateRandomNumber();
    const fileName = `${new Date().toISOString()}_${randomNumber}.pdf`;
    console.log('Uploading file with name:', fileName);

    const { data, error } = await supabase.storage
      .from('cafe-images')
      .upload(`public/${fileName}`, file);

    if (error) {
      console.error('Error uploading PDF:', error);
      throw error;
    }

    console.log('PDF uploaded successfully:', data);
    // return data.path;
    const publicURL = `${supabase.storageUrl}/object/public/cafe-images/public/${fileName}`;
    return publicURL;
  } catch (error) {
    console.error('Error uploading PDF:', error);
    throw error;
  }
};

export const updateMenuItem = async (itemData) => {
  const { itemid, name, price, description, vegan, img } = itemData;
  const updates = {
    name,
    price,
    description,
    vegan,
    img,
  };

  let { error } = await supabase
    .from('menu')
    .update(updates)
    .eq('itemid', itemid);

  if (error) {
    console.error('Error updating menu item:', error);
    throw error;
  }

  console.log('Menu item updated successfully');
};


export const insertMenuItem = async (itemData) => {
  const { name, price, description, vegan, img } = itemData;
  const { data, error } = await supabase.from('menu').insert([
    {
      name,
      price,
      description,
      vegan,
      img,
    },
  ]);

  if (error) {
    console.error('Error inserting menu item:', error);
    throw error;
  }

  console.log('Menu item inserted successfully:', data);
};


// dbhelper.js
export const deleteMenuItem = async (itemid) => {
  const { data, error } = await supabase
    .from('menu')
    .delete()
    .eq('itemid', itemid);

  if (error) {
    console.error('Error deleting menu item:', error);
    throw error;
  }

  console.log('Menu item deleted successfully:', data);
};