import React, { useState } from 'react';
import Home from './Home';

const LoginPage = () => {
  const [passcode, setPasscode] = useState('');
  const [error, setError] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handlePasscodeChange = (e) => {
    setPasscode(e.target.value);
    setError('');
  };

  const handleLogin = () => {
    const correctPasscode = process.env.REACT_APP_PASSCODE;
    if (passcode === correctPasscode) {
      setIsLoggedIn(true);
    } else {
      setError('Wrong passcode');
    }
  };

  return (

  
    <div>
      {isLoggedIn ? (
        <Home />
      ) : (
        <div className="login-container">
        <div className="card">
          <div className="image-container">
            <img src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExeTM5cTA2NDFlbXMwemJ3bmxqenBydjdyZmUwNWI4YWNzZWJrMW1oNSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/pAUg2l9WEV3QA/giphy.gif" alt="login" />
          </div>
          <div className="login-form">
            <label htmlFor="passcode">
              Passcode
            </label>
            <div style={{ height: '8px' }}></div>
            <input
              type="password"
              id="passcode"
              className="default-blue-border"
              value={passcode}
              onChange={handlePasscodeChange}
            />
            <div style={{ height: '8px' }}></div>
            {error && <p className="error-message">{error}</p>}
            <button className="login-blue-button" onClick={handleLogin}>
              Login
            </button>
          </div>
        </div>
        </div>

      )}
    </div>

  );
};

export default LoginPage;