import React, { useState, useEffect } from "react";
// import axios from "axios";
import { Box, FormControl} from "@primer/react";
import "@primer/css/dist/primer.css";
import "./App.css";
import { updateProfile, uploadImage, uploadPDF } from './dbhelper';
const Profile = () => {
  const [formData, setFormData] = useState({
    heading: "",
    subheading: "",
    main_logo: "",
    twitter: "",
    instagram: "",
    facebook: "",
    youtube: "",
    pdf: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  // const [successAlert, setSuccessAlert] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  // const [imageFile, setImageFile] = useState(null);

  const [showWarning, setShowWarning] = useState(false);
  const handleResize = () => {
    if (window.innerWidth < 1500) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };
  useEffect(() => {
    handleResize(); 
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY;
        const apiurl=process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiurl}/info`, {
          method: "GET",
          headers: {
            "api-key": apiKey,
          },
        });
        const data = await response.json();
        setFormData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }; 
    fetchData();
  }, []);
  const handleEdit = () => {
    setIsEditing(true);
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

const handlePdfChange = (event) => {
  const pdfFile = event.target.files[0];
  if (pdfFile) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      pdf: pdfFile,
    }));
    setPdfFile(pdfFile); // Set the pdfFile state
  }
};

const handleImageChange = (event) => {
  const imageFile = event.target.files[0];
  if (imageFile) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      main_logo: imageFile,
    }));
    // setImageFile(imageFile);
     // Set the imageFile state
  }
};


  const handleSave = async (e) => {
    e.preventDefault();
    try {
      let updatedFormData = { ...formData };
      if (formData.main_logo && typeof formData.main_logo !== 'string') {
        try {
          const imagePath = await uploadImage(formData.main_logo);
          console.log("Image uploaded and public URL retrieved:", imagePath);
          updatedFormData = { ...updatedFormData, main_logo: imagePath };
        } catch (error) {
          console.error('Error handling image upload:', error);
        }
      }

        // Upload the PDF file if it exists
        if (pdfFile) {
          console.log("got it");
          const pdfPath = await uploadPDF(pdfFile);
          updatedFormData = { ...updatedFormData, pdf: pdfPath };
          console.log(pdfPath);
        } else {
          console.log("no file");
        }

      await updateProfile(updatedFormData);
      alert("Successfully updated");
      setIsEditing(false);
      setFormData({
        pdf: null,
        main_logo: null,
      });
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };
  return (
    <div>
 {showWarning ? (
        <p>Please expand your browser window to ensure a seamless user experience.</p>
      ) : (

    <div className="container">
      <div className="card">
        <Box p={4}>
          <form >
            <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
              <FormControl mb={3} style={{ flex: "1 1 100%" }}>
                <label className="text-white" style={{ fontWeight: "bold" }}>
                  Heading
                </label>
                <input
                  name="heading"
                  value={formData.heading}
                  onChange={handleChange}
                  disabled={!isEditing}
                  className="default-blue-border"
                  style={{ width: "50vh" }}
                />
              </FormControl>
              <FormControl mb={3} style={{ flex: "1 1 100%" }}>
                <label className="text-white" style={{ fontWeight: "bold" }}>
                  Subheading
                </label>
                <textarea
                  name="subheading"
                  value={formData.subheading}
                  onChange={handleChange}
                  disabled={!isEditing}
                  className="default-blue-border"
                  style={{ width: "50vh", minHeight: "100px" }}
                />
              </FormControl>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
              <FormControl mb={3} style={{ flex: "1 1 calc(50% - 0.5rem)" }}>
                <label className="text-white" style={{ fontWeight: "bold" }}>
                  Twitter
                </label>
                <input
                  name="twitter"
                  value={formData.twitter}
                  onChange={handleChange}
                  disabled={!isEditing}
                  className="default-blue-border"
                  style={{ width: "50vh" }}
                />
              </FormControl>
              <FormControl mb={3} style={{ flex: "1 1 calc(50% - 0.5rem)" }}>
                <label className="text-white" style={{ fontWeight: "bold" }}>
                  Instagram
                </label>
                <input
                  name="instagram"
                  value={formData.instagram}
                  onChange={handleChange}
                  disabled={!isEditing}
                  className="default-blue-border"
                  style={{ width: "50vh" }}
                />
              </FormControl>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
              <FormControl mb={3} style={{ flex: "1 1 calc(50% - 0.5rem)" }}>
                <label className="text-white" style={{ fontWeight: "bold" }}>
                  Facebook
                </label>
                <input
                  name="facebook"
                  value={formData.facebook}
                  onChange={handleChange}
                  disabled={!isEditing}
                  className="default-blue-border"
                  style={{ width: "50vh" }}
                />
              </FormControl>
              <FormControl mb={3} style={{ flex: "1 1 calc(50% - 0.5rem)" }}>
                <label className="text-white" style={{ fontWeight: "bold" }}>
                  YouTube
                </label>
                <input
                  name="youtube"
                  value={formData.youtube}
                  onChange={handleChange}
                  disabled={!isEditing}
                  className="default-blue-border"
                  style={{ width: "50vh" }}
                />
              </FormControl>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
      <FormControl mb={3} style={{ flex: "1 1 50%" }}>
        {/* PDF Upload */}
        <FormControl.Label
                  className="github-blue-label"
                  fontWeight="bold"
                >
                  Menu Pdf
                </FormControl.Label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label
                    htmlFor="pdf-upload"
                    className={`green-button ${!isEditing && "disabled"}`}
                    style={{ marginRight: "0.5rem" }}
                  >
                    Choose File
                    <input
                      type="file"
                      name="pdf"
                      id="pdf-upload"
                      onChange={handlePdfChange}
                      disabled={!isEditing}
                      className="hidden-input"
                    />
                  </label>
                  {formData.pdf && typeof formData.pdf !== "string" && (
                    <a
                      href={URL.createObjectURL(formData.pdf)}
                      download
                      className="text-white"
                      style={{ marginLeft: "0.5rem" }}
                    >
                      {formData.pdf.name}
                    </a>
                  )}
                </div>
        {/* Your existing PDF upload code */}
      </FormControl>
      <FormControl mb={3} style={{ flex: "1 1 50%" }}>
        {/* Logo Upload */}
        <FormControl.Label
                  className="github-blue-label"
                  fontWeight="bold"
                  display="flex"
                  alignItems="center"
                >
                  Logo
                  {formData.main_logo &&
                    typeof formData.main_logo !== "string" && (
                      <div className="card" style={{ marginLeft: "0.5rem" }}>
                        <img
                          src={URL.createObjectURL(formData.main_logo)}
                          alt="Main Logo"
                          width={100}
                          height={100}
                        />
                      </div>
                    )}
                </FormControl.Label>
        {/* Your existing Logo upload code */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <label
            htmlFor="logo-upload"
            className={`green-button ${!isEditing && "disabled"}`}
            style={{ marginRight: "0.5rem" }}
          >
            Choose File
            <input
              type="file"
              name="main_logo"
              id="logo-upload"
              onChange={handleImageChange}
              disabled={!isEditing}
              className="hidden-input"
            />
          </label>
          {isEditing ? (
        <button
          type="button"
          className="green-button"
          style={{ marginLeft: '0.5rem' }}
          onClick={handleSave}
        >
          Save
        </button>
      ) : (
        <button
          type="button"
          onClick={handleEdit}
          className="blue-button"
          style={{ marginLeft: '0.5rem' }}
        >
          Edit
        </button>
      )}
        </div>
      </FormControl>
    </div>
          </form>
          {/* {successAlert && (
            <Flash className="Flash-success" mb={3}>
              Settings updated successfully!
            </Flash>
          )} */}
        </Box>
      </div>
    </div>
     )}
    </div>
  );
};
export default Profile;
