import React, { useState, useEffect } from "react";
import {
  updateMenuItem,
  uploadImage,
  insertMenuItem,
  deleteMenuItem,
} from "./dbhelper";

const Menu = () => {
  const [menu, setMenu] = useState([]);
  const [showModal, setShowModal] = useState(false);
  // const [currentItem, setCurrentItem] = useState(null);
  const [formData, setFormData] = useState({
    itemid: null,
    name: "",
    price: "",
    description: "",
    vegan: false,
    img: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const apiKey = process.env.REACT_APP_API_KEY;
  const apiurl = process.env.REACT_APP_API_URL;


  const [showWarning, setShowWarning] = useState(false);
  const handleResize = () => {
    if (window.innerWidth < 1130) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };
  useEffect(() => {
    handleResize(); 
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    fetch(`${apiurl}/info`, {
      method: "GET",
      headers: {
        "api-key": apiKey,
      },
    })
      .then((response) => response.json())
      .then((data) => setMenu(data.menu))
      .catch((error) => console.error("Error fetching menu data:", error));
  }, [apiKey, apiurl]); // Include apiKey and apiurl here

  const handleUpdateClick = (item) => {
    // setCurrentItem(item);
    setFormData({
      itemid: item.itemid,
      name: item.name,
      price: item.price,
      description: item.description,
      vegan: item.vegan,
      img: item.img,
    });
    setIsEditing(true);
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const imageUrl = await uploadImage(file);
        setFormData({ ...formData, img: imageUrl });
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      if (isEditing) {
        await updateMenuItem(formData);
      } else {
        await insertMenuItem(formData);
        setFormData({
          itemid: null,
          name: "",
          price: "",
          description: "",
          vegan: false,
          img: "",
        });
      }
      setShowModal(false);
      const response = await fetch(`${apiurl}/info`, {
        method: "GET",
        headers: {
          "api-key": apiKey,
        },
      });
      const data = await response.json();
      setMenu(data.menu);
      alert("Menu item added successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleAddClick = () => {
    setIsEditing(false);
    setFormData({
      itemid: null,
      name: "",
      price: "",
      description: "",
      vegan: false,
      img: "",
    });
    setShowModal(true);
  };

  const handleDeleteClick = async (itemid) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this menu item?");
  
    if (confirmDelete) {
      try {
        await deleteMenuItem(itemid);
        const response = await fetch(`${apiurl}/info`, {
          method: "GET",
          headers: {
            "api-key": apiKey,
          },
        });
        const data = await response.json();
        setMenu(data.menu);
        alert("Menu item deleted successfully!");
      } catch (error) {
        console.error("Error deleting menu item:", error);
        alert("Error deleting menu item. Please try again later.");
      }
    }
  };

  return (
    <div>
    {showWarning ? (
      <p>Please expand your browser window to ensure a seamless user experience.</p>
    ) : (
    <div className="menu-container">
      <table className="menu-table">
        <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Price</th>
            <th>Description</th>
            <th>Vegan</th>
            <th> <button className="blue-button" onClick={handleAddClick}>
        Add
      </button></th>
          </tr>
        </thead>
        <tbody>
          {menu.map((item) => (
            <tr key={item.itemid}>
              <td>
                <img src={item.img} alt={item.name} className="menu-image" />
              </td>
              <td>{item.name}</td>
              <td>{item.price}</td>
              <td>{item.description}</td>
              <td>{item.vegan ? "Yes" : "No"}</td>
              <td className="menu-actions">
                <button
                  className="update-btn"
                  onClick={() => handleUpdateClick(item)}
                >
                  Update
                </button>
                <button
                  className="delete-btn"
                  onClick={() => handleDeleteClick(item.itemid)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showModal && (
        <>
          <div
            className="menu-overlay menu-overlay-active"
            onClick={() => setShowModal(false)}
          ></div>
          <div className="menu-modal menu-modal-active">
            <div className="menu-modal-header">
              {isEditing ? "Update Menu Item" : "Add Menu Item"}
            </div>
            <div className="menu-modal-body">
              <div className="menu-modal-left">
                <label>
                  Name
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                  Price
                  <input
                    type="text"
                    name="price"
                    value={formData.price}
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                  Description
                  <input
                    type="text"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                  />
                </label>
                <label className="menu-checkbox-label">
                  <input
                    type="checkbox"
                    name="vegan"
                    checked={formData.vegan}
                    onChange={(e) =>
                      setFormData({ ...formData, vegan: e.target.checked })
                    }
                  />
                  Vegan
                </label>
              </div>

              <div className="menu-modal-right">
                {formData.img && (
                  <img
                    src={formData.img}
                    alt="Preview"
                    className="menu-image-preview"
                  />
                )}
              </div>
            </div>

            <div className="menu-modal-footer">
              <div className="left-buttons">
                <label
                  htmlFor="file-upload"
                  className="custom-file-upload blue-button"
                >
                  {isEditing ? "Change Image" : "Add Image"}
                </label>
                <input
                  id="file-upload"
                  type="file"
                  name="img"
                  onChange={handleFileChange}
                />
              </div>
              <div className="right-buttons">
                <button className="submit-btn" onClick={handleSubmit}>
                  {isEditing ? "Update" : "Add"}
                </button>
                <button
                  className="cancel-btn"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
     )}
     </div>
  );
};

export default Menu;
