import React, { useState } from 'react';
import Profile from './Profile';
import Menu from './Menu';
import Offers from './Offers';
import './App.css';

const Home = () => {
  const [activeTab, setActiveTab] = useState('Menu');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="container">
      <div className="title">WebCafe CMS</div>
      <div className="card">
        <div className="tab-container">
          <div
            className={`tab ${activeTab === 'Menu' ? 'active' : ''}`}
            onClick={() => handleTabClick('Menu')}
          >
            Menu
          </div>
          <div
            className={`tab ${activeTab === 'Offers' ? 'active' : ''}`}
            onClick={() => handleTabClick('Offers')}
          >
            Offers
          </div>
          <div
            className={`tab ${activeTab === 'Profile' ? 'active' : ''}`}
            onClick={() => handleTabClick('Profile')}
          >
            Profile
          </div>
        </div>
        <div className="tab-content">
          {activeTab === 'Menu' && <Menu />}
          {activeTab === 'Offers' && <Offers />}
          {activeTab === 'Profile' && <Profile />}
        </div>
      </div>
    </div>
  );
};

export default Home;